<template>
    <div>
        <van-cell-group>
            <div style="display:flex;padding:.3rem .6rem;font-size: .28rem;color:black;align-items: center">
                <div style="width:1rem;height:1rem;background: #e7e7e7;border-radius: 50%">
                    <img src="../assets/teacher.png" style="width:100%;height:100%;border-radius: 50%;"/>
                </div>
                <div style="margin-left: .2rem;height:1rem;flex:1;display: flex;flex-direction: column">
                    <h4 style="flex:1">
                        {{user.name}}
                    </h4>
                    <h5 v-if="user.xz_identity!==''" style="flex:1">{{user.xz_identity||''}}</h5>

                    <div style="flex:1;color:gray;font-size: .25rem">
                        {{user.phone}}
                    </div>
                </div>
<!--                <div @click="judge" style="display: flex;align-items: center">-->
<!--                    {{isBind?'已绑定':'去绑定'}}<van-icon color="gray" v-if="!isBind" name="arrow" />-->
<!--                </div>-->
            </div>
            <van-cell title="查看账号" is-link to="./login"/>
            <van-cell title="转让积分" is-link @click="showPopup" />
            <van-cell title="墨豆明细" is-link to="./point"/>

        </van-cell-group>
        <van-popup style="width:80%;border-radius: .2rem" v-model="show_pop" bind:close="onClose">
            <h1 style="text-align: center;margin: .2rem 0">转让墨豆</h1>
            <div style="height:3rem">
                <van-field
                        v-model="send_phone"
                        center
                        clearable
                        label="转让墨豆"
                        type="tel"
                        placeholder="请输入手机号"
                        @input="inputNumber"
                >
                </van-field>
                <van-field
                        v-if="name"
                        v-model="integral"
                        center
                        clearable
                        :label="name"
                        placeholder="请输入墨豆数"
                >

                    <template #button>
                        <van-button size="small" @click="giveIntegral" type="primary">转让</van-button>
                    </template>

                </van-field>
            </div>

        </van-popup>

    </div>
</template>

<script>
    import BindPhone from "./BindPhone/BindPhone";

    import { Toast,Dialog } from 'vant';

    export default {
        name: "profile",
        data(){
            return{
                user:{},
                isBind:null,

                send_phone:'',//转让积分的手机号
                name:'',
                integral:'',

                show_pop:false
            }
        },
        components:{
            BindPhone
        },
        activated(){
            this.isBind=JSON.parse(localStorage.getItem('is_bind'))
            this.$axios.get('miniprogram/integralUser').then((res)=>{
                if(res.data.code==='0000')
                {
                    this.user=res.data.content.user;
                }
            })
        },
        created() {
            this.isBind=JSON.parse(localStorage.getItem('is_bind'))

            // miniprogram/integralUser
            this.$axios.get('miniprogram/integralUser').then((res)=>{
                if(res.data.code==='0000')
                {
                    this.user=res.data.content.user;
                }
            })
        },
        methods:{
            showPopup() {
                console.log(11111)
                this.show_pop=true
            },

            onClose() {
                this.show_pop=false
            },
            inputNumber(){
                let TEL_REGEXP = /^1\d{10}$/;
                if (!this.send_phone) {
                    this.name=''
                    // Toast('请输入手机号');
                } else if (!TEL_REGEXP.test(this.send_phone)) {
                    this.name=''
                    // Toast('请输入正确手机号');
                } else {
                    this.$axios.get('miniprogram/checkPresentPhone?phone='+this.send_phone).then((res)=>{
                        if(res.data.code==='0000')
                        {
                            this.name=res.data.content.name;
                        }else{
                            Toast(res.data.content)
                        }

                    })
                }
            },
            giveIntegral(){
                // this.send_phone=''
                this.$axios.post('miniprogram/presentIntegral',{
                    to_phone:this.send_phone,
                    integral:this.integral
                }).then((res)=>{
                    console.log(res)
                    if(res.data.code==='0000')
                    {
                        this.send_phone='';
                        this.name='';
                    }
                    Toast(res.data.content)

                })
            },
            judge(){
                if(!this.isBind)
                {
                    this.$router.push('/login')
                }
            },
            turnTo(){
                this.$router.push('/login')
            }
        }

    }
</script>

<style scoped>

</style>
